import React, { useEffect } from 'react';

import Countdown from '../components/countdown/Countdown';
import Hero from '../components/hero/Hero';
import Message from '../components/message/Message';
import Page from '../components/page/Page';
import activeLearningIcon from '../images/active_learning_icon.png';
import galleryImage3 from '../images/DSC_3406.jpeg';
import galleryImage1 from '../images/DSC_3682.jpeg';
import headerImage3 from '../images/DSC_3700.jpeg';
import headerImage4 from '../images/DSC_3706.jpeg';
import galleryImage2 from '../images/DSC_4061.jpeg';
import headerImage6 from '../images/DSC_4345.jpeg';
import headerImage5 from '../images/DSC_4455.jpeg';
import headerImage2 from '../images/DSC_4539.jpeg';
import headerImage1 from '../images/DSC_4541.jpeg';
import nutritiousMenuIcon from '../images/nutritious_menu_icon.png';
import qualityNappiesIcon from '../images/quality_nappies_icon.png';
import data from '../site-data.json';
import styles from './home.module.scss';

const Home = (): JSX.Element => {
  useEffect(() => {
    const images = [
      headerImage1,
      headerImage2,
      headerImage3,
      headerImage4,
      headerImage5,
      headerImage6,
    ];
    setTimeout(() => {
      // eslint-disable-next-line array-callback-return
      images.map((image) => {
        const img = new Image();
        img.src = image;
      });
    }, 2000);
  });

  return (
    <Page title={`${data.siteName} - Home`}>
      <Hero
        title="Early Learning On Bribie"
        subtitle="The Village You've Been Looking For"
        youtubeVideoId="0FwxRw6vfHc"
        cta={{
          href: '/contact',
          isExternalLink: true,
          buttonStyle: 'solid',
          buttonText: 'Book A Tour',
          buttonBackgroundColour: '',
          buttonTextColour: '',
        }}
      />
      <Message
        title="Welcome to your village"
        subtitle={`'It takes a village to raise a child'... At Early Learning On Bribie we are your village.`}
        body={[
          `From the minute your family walks through the front door you'll
            feel a real sense of home, where your child has secure bonds with
            our educators who provide a safe and healthy learning environment
            for your child to have a great day - every day.`,
          `We are more than childcare. We are your people. We are your trusted
            community. We are your village.`,
        ]}
        backgroundColour="accent4"
      />
      <Countdown
        image={galleryImage1}
        backgroundColour="accent3"
        itemGrid={{
          title: 'Why Choose Us',
          items: [
            [
              {
                icon: qualityNappiesIcon,
                label: 'Quality nappies, wipes and bedding all provided',
              },
              {
                icon: nutritiousMenuIcon,
                label:
                  'Delicious cooked meals are prepared daily in line with nutritional requirements',
              },
              {
                icon: activeLearningIcon,
                label:
                  'Active learning through play, indoors and in the natural environment',
              },
            ],
          ],
        }}
      />
      <Countdown
        imageLeft
        image={galleryImage2}
        backgroundColour="accent3"
        itemGrid={{
          title: 'Our Programs',
          circles: [
            {
              circleBackgroundColour: '#e2ad60',
              circleTextColour: '#faf7f2',
              circleText: 'Early Learning',
              label:
                'Birth to 2 is a critical time of growth and development, we offer loving and caring environments to ensure your child feels secure.',
            },
            {
              circleBackgroundColour: '#d99a88',
              circleTextColour: '#faf7f2',
              circleText: 'Toddlers',
              label:
                'For this dynamic and busy period, our toddler programs provide the space for safe exploration and discovery to interact with and understand the world around them.',
            },
            {
              circleBackgroundColour: '#4c5173',
              circleTextColour: '#faf7f2',
              circleText: 'Preschool',
              label:
                'We take a holistic approach to our school readiness program to ensure children enter school with the ability to learn, play and participate with the confidence to be the best they can be.',
            },
          ],
        }}
      />
      <Countdown
        image={galleryImage3}
        backgroundColour="accent3"
        title="Come and Say Hello..."
        content={
          <>
            <p>
              Come and see for yourself, get that feeling of home, as you walk
              through the front door of Early Learning On Bribie.
            </p>
            <p>
              Talk to our team about ways we can support your family&apos;s
              childcare and early learning needs today.
            </p>
            <div className={styles.ctaCentered}>
              <a
                href="https://ccs-earlylearningonbribie.kinderm8.com.au/enquiry-form"
                target="_blank"
                className={styles.ctaLink}
                rel="noopener noreferrer"
              >
                <button className={styles.solidButton} type="button">
                  Enquire Now
                </button>
              </a>
            </div>
          </>
        }
      />
      <Message
        title="‘We are your village of dedicated and passionate educators ensuring your children have a great day, every day’"
        backgroundColour="accent4"
      />
    </Page>
  );
};

export default Home;
