/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import 'photoswipe/dist/photoswipe.css';

import React from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';

import Page from '../../components/page/Page';
import PageHeader from '../../components/pageHeader/PageHeader';
import galleryImage1 from '../../images/DSC_3587.jpeg';
import galleryImage1Thumbnail from '../../images/DSC_3587_thumbnail.jpeg';
import galleryImage2 from '../../images/DSC_3694.jpeg';
import galleryImage2Thumbnail from '../../images/DSC_3694_thumbnail.jpeg';
import galleryImage3 from '../../images/DSC_3953.jpeg';
import galleryImage3Thumbnail from '../../images/DSC_3953_thumbnail.jpeg';
import headerImage from '../../images/DSC_4539.jpeg';
import styles from './program.module.scss';

const ProgramEarlyLearning = (): JSX.Element => (
  <Page title="Early Learning Room" pageClassName={styles.page}>
    <PageHeader title="Early Learning Room" image={headerImage} />
    <div className={styles.content}>
      <h1>Birth to 2 is a critical time of development</h1>
      <p>
        We offer all the love and care your baby needs to ensure they feel
        secure. Our babies have safe, dedicated indoor and outdoor spaces for
        free exploration with educators who love engaging (and lots of cuddles)
        with the newest members of the Early Learning On Bribie Family.
      </p>
      <p>
        We support all your family&apos;s needs for a smooth transition from
        home to care. This includes welcoming parents who choose to breastfeed
        during the day.
      </p>
      <p>Our early learning program includes:</p>
      <ul>
        <li>quality nappies and wipes</li>
        <li>dedicated sleep room for babies</li>
        <li>
          specific menu designed with nutritious and delicious meals prepared
          fresh daily
        </li>
        <li>daily communication with families via Kinderm8 app</li>
        <li>safe play spaces devoted to the needs of babies</li>
        <li>
          our staff have completed the Department of Education’s Safe Sleep
          Training and all children are physically and consistently checked
          during sleep time as per the Safe Sleeping guidelines
        </li>
      </ul>
      <div className={styles.ctaLinkContainer}>
        <a
          href="https://ccs-earlylearningonbribie.kinderm8.com.au/enrolment-form"
          target="_blank"
          className={styles.ctaLink}
          rel="noopener noreferrer"
        >
          <button className={styles.solidButton} type="button">
            Enrol Now
          </button>
        </a>
      </div>

      <Gallery>
        <ul className={styles.imageGallery}>
          <Item
            original={galleryImage1}
            thumbnail={galleryImage1Thumbnail}
            width="1200"
            height="800"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage1Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
          <Item
            original={galleryImage2}
            thumbnail={galleryImage2Thumbnail}
            width="1200"
            height="800"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage2Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
          <Item
            original={galleryImage3}
            thumbnail={galleryImage3Thumbnail}
            width="1079"
            height="900"
          >
            {({ ref, open }) => (
              <li>
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onClick={open}
                  src={galleryImage3Thumbnail}
                  alt="1"
                  className={styles.galleryImage}
                />
              </li>
            )}
          </Item>
        </ul>
      </Gallery>
    </div>
  </Page>
);

export default ProgramEarlyLearning;
